module.exports = [{
      plugin: require('/Users/kyleboss/Projects/fast-phone-lookup/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/kyleboss/Projects/fast-phone-lookup/node_modules/gatsby-plugin-heap/gatsby-browser'),
      options: {"plugins":[],"appId":"2156039214","enableOnDevMode":false},
    },{
      plugin: require('/Users/kyleboss/Projects/fast-phone-lookup/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
